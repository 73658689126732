import React, { useEffect, useState } from 'react';
import axios from "axios";
import { useParams, useNavigate } from 'react-router-dom';

import {Grid, List, ListItem, Typography, Link, Collapse, ListItemText, Container, Button, Box} from '@mui/material';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import Pictures from './Pictures';
import Puustotiedot from './Puustotiedot';
import OwnerInfo from './OwnerInfo';

import { formatNumberWithSeparator, checkIfAnyPuustotieto, fixUrl } from '../../utils';
import zIndex from '@mui/material/styles/zIndex';

const outerContainerStyle = {
  marginTop: (theme) => theme.spacing(2),
  marginBottom: (theme) => theme.spacing(10),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}

const buttonContainerStyle = {
  display: "block",
  flexDirection: "initial",
  alignItems: "initial", 
  marginBottom: (theme) => theme.spacing(2),
}


const containerStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  borderStyle: 'solid',
  borderWidth: '5px',
  borderColor: (theme) => theme.palette.primary.light,
  borderRadius: '25px',
}

const innerContainerStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  paddingTop: (theme) => theme.spacing(6),
  borderRadius: '25px',
}

const headerInfoStyle = {
  width: '100%',
}

const buttonStyle = {
  marginTop: '20px',
  marginLeft:  {xs: '0px', sm: '10px'},
  padding: '10px',
  fontSize: {xs: '12px', sm: '14px'},
  width: {xs: '40%', sm: "20%", md: "10%"},
  backgroundColor: (theme) => theme.palette.primary.main
}

const lisatiedotStyle = {
  marginTop: (theme) => theme.spacing(2),
  marginBottom: (theme) => theme.spacing(2),
  backgroundColor: '#E8E8E8',
  padding: '15px'
}

const headerStyle = {
  px: (theme) => theme.spacing(2),
  py: (theme) => theme.spacing(1)
}

const adContainerStyle = {
  width: {xs: '100%', sm: "600px", md: "600px"},
  minHeight: '200px',
}

const fixedAdContainerStyle = {
  width: '100%', // Adjust the width as needed
  height: '70vh', // Adjust the height as needed
  zIndex: 1000
};

const adStyle = {
  width: '100%',
  height: '100%',
  objectFit: 'contain'
}

export default function Ilmoitus() {
  const [ilmoitustiedot, setIlmoitustiedot] = useState({})
  const [omistajatiedot, setOmistajatiedot] = useState({})

  const { id } = useParams()
  const navigate = useNavigate();

  const [openPuustotiedot, setOpenPuustotiedot] = useState(true)
  const [openYhteismetsatiedot, setOpenYhteismetsatiedot] = useState(true)
  const [openLisatiedot, setOpenLisatiedot] = useState(true)

  useEffect(() => {
    window.scrollTo(0, 0);
    
    getIlmoitus(id)
    getIlmoitusOwner(id)
  },[]);

  const getIlmoitus = async (ilmoitus_id) => {
    try {
      const response = await axios.get("/api/ilmoitus/hae-ilmoitus/" + ilmoitus_id)
      if (response.data.tarjouskauppa_paattyy) {
        const date = new Date(response.data.tarjouskauppa_paattyy);
        response.data.tarjouskauppa_paattyy = date.toLocaleString('fi-FI', {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
          hour: '2-digit',
          minute: '2-digit'
        })
      }

      if (response.data.metsa_arvio_ajankohta) {
        const date = new Date(response.data.metsa_arvio_ajankohta);
        response.data.metsa_arvio_ajankohta = date.toLocaleString('fi-FI', {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
        });
      }

      setIlmoitustiedot(response.data)
    } catch(error) {
      navigate('/haku');
    }
  }

  const getIlmoitusOwner = async (ilmoitus_id) => {
    try {
      const response = await axios.get("/api/ilmoitus/hae-omistaja/" + ilmoitus_id)

      const fullAddress = formatOwnerAddress(response.data.city, response.data.address)

      response.data.fullAddress = fullAddress

      setOmistajatiedot(response.data)
    } catch(error) {
      console.log(error)
    }
  }

  const formatOwnerAddress = (city, address) => {
    if (address && city) {
      return `${address}, ${city}`
    } else if (address) {
      return address
    } else if (city) {
      return city
    } else {
      return "-"
    }
  }

  const getAttachment = async (liite_url, liite_name) => {
    try {

      // Asetetaan sivulle latausindikaattori
      document.body.style.cursor = 'wait';

      // Haetaan liite urlin avulla blob storagesta ja luodaan tiedosto-objekti
      const config = { responseType: 'blob' };
      const response = await axios.get(liite_url, config);
      const liite_file = new File([response.data], liite_name, { type: response.type });

      // Muodostetaan object url ja dummy-linkki jonka avulla liite ladataan käyttäjälle.
      const url = window.URL.createObjectURL(liite_file);
      const link = document.createElement('a');       
      link.href = url;
      link.download = liite_name;
      document.body.appendChild(link);
      link.click();

      // Lopuksi poistetaan dummy-linkki ja object url
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url)

      document.body.style.cursor = 'auto';
    } catch(error) {
      document.body.style.cursor = 'auto';
      console.log(error)
    }
  }

  return (
    <Container maxWidth={false} sx={outerContainerStyle}>
      <Container maxWidth={false} sx={buttonContainerStyle}>
        <Button variant="contained" sx={buttonStyle} onClick={() => {window.history.go(-1); return false;}}>Edellinen</Button>
      </Container>
      <Container maxWidth="md" sx={containerStyle}>
        <Grid container xs={12} sx={innerContainerStyle}>
          <Grid item container xs={12} sx={headerInfoStyle}>
            <Grid item xs={12}>
              <Typography variant="body1" sx={{textAlign: 'center'}}><b>{ilmoitustiedot.tyyppi}, {ilmoitustiedot.kiinteistonimi}, {ilmoitustiedot.kiinteistotunnus}</b></Typography>
            </Grid>
          </Grid>
        { ilmoitustiedot.kuvat?.length > 0 &&
          <Grid item xs={12} sm={12}>
            <Pictures kuvat={ilmoitustiedot.kuvat} />
          </Grid>
        }
        { ilmoitustiedot.lisatietoja &&
          <Grid item xs={12} sx={lisatiedotStyle}>
            <Typography variant="body1" align="left" whiteSpace="pre-line">{ilmoitustiedot.lisatietoja} </Typography>
          </Grid>
        }
        <List sx={{maxWidth: '100%'}}>
          <Typography variant="h4" sx={headerStyle}><b>Perustiedot</b></Typography>
          <ListItem sx={{marginBottom: '50px'}}>
            <Grid item container spacing={2} xs={12}>
              <Grid item xs={6} sm={6} md={4}>
                <Typography variant="body1"><b>Tilan pinta-ala: </b><br/>{Number(ilmoitustiedot.koko).toFixed(1)} ha</Typography>
              </Grid>
              <Grid item xs={6} sm={6} md={4}>
                <Typography variant="body1"><b>Metsämaan pinta-ala: </b><br/>{ilmoitustiedot.metsamaa_koko ? Number(ilmoitustiedot.metsamaa_koko).toFixed(1) + " ha" : "-"} </Typography>
              </Grid>
              <Grid item xs={6} sm={6} md={4}>
                <Typography variant="body1"><b>Muun maan pinta-ala: </b><br/>{ilmoitustiedot.muumaa_koko ? Number(ilmoitustiedot.muumaa_koko).toFixed(1) + " ha" : "-"} </Typography>
              </Grid>
              <Grid item xs={6} sm={6} md={4}>
                <Typography variant="body1"><b>Sijainti: </b><br/>
                  {ilmoitustiedot.kunta ? ilmoitustiedot.kunta : ""}{ilmoitustiedot.kunta && ilmoitustiedot.maakunta ? ", " : ""} {ilmoitustiedot.maakunta ? ilmoitustiedot.maakunta : ""} {ilmoitustiedot.kunta || ilmoitustiedot.maakunta ? "" : "-"}
                </Typography>
              </Grid>
              <Grid item xs={6} sm={6} md={4}>
                <Typography variant="body1"><b>Hintapyyntö: </b><br/>{formatNumberWithSeparator(ilmoitustiedot.hintapyynto)} €</Typography>
              </Grid>
              <Grid item xs={6} sm={6} md={4} sx={{display: ilmoitustiedot.puu_maara ? 'block' : 'none' }}>
                <Typography variant="body1"><b>Puun määrä: </b><br/>{ilmoitustiedot.puu_maara + " m³"} </Typography>
              </Grid>
              <Grid item xs={6} sm={6} md={4} sx={{display: ilmoitustiedot.yritysnimi !== null ? 'block' : 'none' }}>
                <Typography variant="body1"><b>Yritys: </b><br/>{ilmoitustiedot.yritysnimi}</Typography>
              </Grid>
              <Grid item xs={6} sm={6} md={4} sx={{display: ilmoitustiedot.metsa_arvio !== 'Ei ole' ? 'block' : 'none' }}>
                <Typography variant="body1"><b>Arvio: </b><br/>{ilmoitustiedot.metsa_arvio}, {ilmoitustiedot.metsa_arvio_ajankohta}</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4} sx={{display: ilmoitustiedot.tarjouskauppa === 'true' ? 'block' : 'none'}}>
                <Typography variant="body1"><b>Tarjous jätettävä viimeistään</b><br/>{ilmoitustiedot.tarjouskauppa_paattyy}</Typography>
              </Grid>
            </Grid>
          </ListItem>
          {checkIfAnyPuustotieto(ilmoitustiedot) &&
            <React.Fragment>
              <ListItem button onClick={() => {setOpenPuustotiedot(!openPuustotiedot)}}>
                  <ListItemText primary={<Typography variant="h4"><b>Puustotiedot</b></Typography>} />
                  {openPuustotiedot ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={openPuustotiedot} timeout="auto" sx={{marginBottom: '50px'}}>
                <Puustotiedot ilmoitustiedot={ilmoitustiedot} />
              </Collapse>
              </React.Fragment>
          }
            {ilmoitustiedot.yhteismetsa_osuudet &&
              <div>
                <ListItem button onClick={() => {setOpenYhteismetsatiedot(!openYhteismetsatiedot)}}>
                  <ListItemText primary={<Typography variant="h4"><b>Yhteismetsätiedot</b></Typography>} />
                  {openYhteismetsatiedot ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={openYhteismetsatiedot} timeout="auto" sx={{marginBottom: '50px'}}>
                  <List >
                    <ListItem>
                      <Typography variant="body1" sx={{width:'100%'}}><b>Nimi:</b> {ilmoitustiedot.yhteismetsa_nimi } </Typography>
                    </ListItem>
                    <ListItem>
                      <Typography variant="body1" sx={{width:'100%'}}><b>Kotisivut:</b><a href={fixUrl(ilmoitustiedot.yhteismetsa_kotisivut)} target="_blank" rel="noreferrer" style={{textDecoration: 'none' }}> {ilmoitustiedot.yhteismetsa_kotisivut} </a></Typography>
                    </ListItem>
                    <ListItem>
                      <Typography variant="body1" sx={{width:'100%'}}><b>Koko:</b> {ilmoitustiedot.yhteismetsa_koko ? Number(ilmoitustiedot.yhteismetsa_koko).toFixed(1) + " ha" : "-"} </Typography>
                    </ListItem>
                    <ListItem>
                      <Typography variant="body1" sx={{width:'100%'}}><b>Myynnissä olevat osuudet:</b> {ilmoitustiedot.yhteismetsa_osuudet}/{ilmoitustiedot.yhteismetsa_kokonaisosuudet} </Typography>
                    </ListItem>
                  </List>
                </Collapse>
              </div>
            }
            <ListItem button onClick={() => {setOpenLisatiedot(!openLisatiedot)}}>
              <ListItemText primary={<Typography variant="h4"><b>Lisätiedot ja liitteet</b></Typography>} />
              {openLisatiedot ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={openLisatiedot} timeout="auto" sx={{marginBottom: '50px'}}>
              <Grid container>
                <Grid item xs={12} sm={5} >
                  <List>
                    <ListItem>
                      <Typography variant="body1"><b>Rasitteet ja rajoitukset:</b> {ilmoitustiedot.rajoitteet } </Typography>
                    </ListItem>
                    <ListItem>
                      <Typography variant="body1"><b>Tieoikeudet:</b> {ilmoitustiedot.tieoikeudet } </Typography>
                    </ListItem>
                    <ListItem>
                      <Typography variant="body1"><b>Osuus yhteisiin:</b> {ilmoitustiedot.osuus_yhteisiin } </Typography>
                    </ListItem>
                    <ListItem>
                      <Typography variant="body1"><b>Kaava:</b> {ilmoitustiedot.kaava } </Typography>
                    </ListItem>
                    <ListItem>
                      <Typography variant="body1"><b>Rakennusoikeus:</b> {ilmoitustiedot.rakennusoikeus} </Typography>
                    </ListItem>
                  </List>
                </Grid>
                <Grid item xs={12} sm={7} >
                  {ilmoitustiedot.liitteet?.map((liite) => {
                    return (
                      <ListItem key={liite.liite_name} >
                        <Link component="button" variant="body1" sx={{width:'100%', wordWrap:'break-word'}} onClick={() => {getAttachment(liite.liite_url, liite.liite_name)}} >{liite.liite_name}</Link>
                      </ListItem>
                    )
                  })}
                </Grid>
                <Grid item xs={12} sm={12} sx={{marginTop: '-16px'}}>
                  <List>
                    {ilmoitustiedot.videolinkki &&
                      <ListItem>
                        <Typography variant="body1"><b>Video: </b><a href={fixUrl(ilmoitustiedot.videolinkki)} target="_blank" rel="noreferrer" style={{textDecoration: 'none', overflowWrap: 'anywhere' }}> {ilmoitustiedot.videolinkki} </a> </Typography>
                      </ListItem>
                    }
                    {ilmoitustiedot.kotisivulinkki &&
                      <ListItem>
                        <Typography variant="body1"><b>Kotisivut: </b><a href={fixUrl(ilmoitustiedot.kotisivulinkki)} target="_blank" rel="noreferrer" style={{textDecoration: 'none', overflowWrap: 'anywhere' }}> {ilmoitustiedot.kotisivulinkki} </a> </Typography>
                      </ListItem>
                    }
                    {ilmoitustiedot.tarjousohjeet &&
                      <ListItem>
                        <Typography variant="body1"><b>Tarjousohjeet: </b>{ilmoitustiedot.tarjousohjeet} </Typography>
                      </ListItem>
                    }
                  </List>
                </Grid>
              </Grid>
            </Collapse>
            <Grid container >
              <Typography variant="h4" sx={headerStyle}><b>Yhteystiedot</b></Typography>
              <Grid item xs={12}>
                <OwnerInfo omistajatiedot={omistajatiedot} yritysnimi={ilmoitustiedot.yritysnimi}/>
              </Grid>
            </Grid>
          </List>
        </Grid>
      </Container>
      <Box component="div" sx={adContainerStyle}>
          <a style={adStyle} href="../palvelut"><img src={'../nettimetsa-valittajat.jpg'} style={adStyle} alt={""}/></a>
      </Box>
    </Container>
  );
}
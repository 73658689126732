import React, { useState, useEffect } from 'react';
import {ImageList, ImageListItem, Container, Box, useMediaQuery  } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import ImageModal from './ImageModal';

const containerStyle = {
  marginTop: (theme) => theme.spacing(2),
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
}

const imageListStyles = {
  overflowX: 'auto', // allow horizontal scrolling
  display: 'flex',
  flexWrap: 'nowrap',
  rowHeight: 160,
  width: '100%'
}

export default function Pictures({ kuvat }) {
  const [openModal, setOpenModal] = useState(false);
  const [selectedPicture, setSelectedPicture] = useState({})

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    if (kuvat) {
      const picture = kuvat.find(kuva => kuva.paakuva === 1)
      setSelectedPicture(picture)
    }
  }, [kuvat]);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const setNextPicture = () => {
    const index = kuvat.findIndex(kuva => kuva.ilmoituskuva_id === selectedPicture.ilmoituskuva_id)
    setSelectedPicture(kuvat[index + 1])
  };

  const setEarlierPicture = () => {
    const index = kuvat.findIndex(kuva => kuva.ilmoituskuva_id === selectedPicture.ilmoituskuva_id)
    setSelectedPicture(kuvat[index - 1])
  };

  return (
    <Container sx={containerStyle}>
      <Box
        component="img"
        sx={{
          cursor:{xs: 'auto', sm: 'pointer' },
          height: {xs: 320, sm: 480 },
          width: '100%',
          maxWidth: '640px',
        }}
        alt="valittu pääkuva"
        src={selectedPicture?.kuva_url}
        onClick={() => {handleOpenModal()}}
      />
      <ImageList sx={imageListStyles} cols={4} >
        {kuvat?.map((kuva) => (
          <ImageListItem button onClick={() => {setSelectedPicture(kuva)}} key={kuva.kuva_url} >
            <img src={kuva.kuva_url} alt="" style={{height: 160, width: 160, cursor: 'pointer' }} loading="lazy"/>
          </ImageListItem>
        ))}
      </ImageList>
      {!isMobile &&
        <ImageModal
          open={openModal}
          imageUrl={selectedPicture.kuva_url}
          onClose={handleCloseModal}
          setNextPicture={setNextPicture}
          setEarlierPicture={setEarlierPicture}
          hasEarlierPictures={kuvat.findIndex(kuva => kuva.ilmoituskuva_id === selectedPicture.ilmoituskuva_id) !== 0}
          hasNextPictures={kuvat.findIndex(kuva => kuva.ilmoituskuva_id === selectedPicture.ilmoituskuva_id) !== kuvat.length - 1}
        />
      }
    </Container>
  );
}
import React from 'react';
import { Container, Typography, Box } from '@mui/material';

import axios from "axios";

const containerStyle = {
  marginBottom: (theme) => theme.spacing(8),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};

const headerImageStyle = {
  width: '100vw',
  height: '100%',
  objectFit: 'cover',
}

const headerContainerStyle = {
  height: {xs: '240px', sm: '360px'},
  position: 'relative',
  marginBottom: (theme) => theme.spacing(4),
};

const headerTextStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  textAlign: 'center',
  color: '#ffffff',
  fontSize: {xs: '32px', sm: '72px'},
  lineHeight: '200%'
};

const headerStyle = {
    marginTop: (theme) => theme.spacing(4),
    marginBottom: (theme) => theme.spacing(2),
    alignItems: 'center',
    textAlign: 'center',
    overflowWrap: 'anywhere',
    fontSize: {xs: '24px', sm: '28px'},
  };

const textStyle = {
    fontSize: {xs: '14px', sm: '16px'},
    width: '80%',
    textAlign: 'center',
};

export default function Mediamyynti() {

  const getNettimetsaEsite = async () => {
    try {

      // Asetetaan sivulle latausindikaattori
      document.body.style.cursor = 'wait';

      // Haetaan liite urlin avulla blob storagesta ja luodaan tiedosto-objekti
      const config = { responseType: 'blob' };
      const name = "Nettimetsä esite.pdf";

      const response = await axios.get("/api/data/nettimetsa-esite", config);
      const file = new File([response.data], name, { type: response.type });

      // Muodostetaan object url ja dummy-linkki jonka avulla liite ladataan käyttäjälle.
      const url = window.URL.createObjectURL(file);
      const link = document.createElement('a');       
      link.href = url;
      link.download = name;
      document.body.appendChild(link);
      link.click();

      // Lopuksi poistetaan dummy-linkki ja object url
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url)

      document.body.style.cursor = 'auto';
    } catch(error) {
      document.body.style.cursor = 'auto';
      console.log(error)
    }
  }

  return (
    <Container maxWidth="lg" sx={containerStyle}>
        <Box component="div" sx={headerContainerStyle}>
          <img src={'../header-image-nettimetsa.png'} alt={""} style={headerImageStyle} />
          <Typography variant='h1' sx={headerTextStyle}><b>Nettimetsä.fi</b></Typography>
        </Box>
        <Typography variant='h4' sx={headerStyle}>Mainosta Nettimetsässä – Tavoita metsänomistajat</Typography>
        <Typography variant="body2" paragraph sx={textStyle}>
          Nettimetsä tarjoaa alustan metsäalan mainontaan. Voit hyödyntää mediaratkaisujamme metsänomistajien tavoittamiseksi.
        </Typography>
        <Typography variant='h4' sx={headerStyle}>Lisätietoja</Typography>
        <Typography variant="body2" paragraph sx={textStyle}>
          Lataa <a href='javascript:void(0)' onClick={() => {getNettimetsaEsite()}}>esitteemme</a>, joka sisältää kuvaukset tarjolla olevista mainosratkaisuista, palveluista sekä hinnastomme.
            Voit myös ottaa suoraan yhteyttä sähköpostitse: <a href='mailto:yllapito@nettimetsa.fi'>yllapito@nettimetsa.fi</a>
        </Typography>
    </Container>
  );
}